import { Day, DayConfig, DayWithConfig, WeekConfig } from "./../../interfaces/day-config";
import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, ChangeDetectorRef } from "@angular/core";
import * as moment from 'moment';

@Component({
  selector: 'app-weekday',
  templateUrl: './week-day.component.html',
  styleUrls: ['./week-day.component.scss'],
})
export class WeekDayComponent implements OnInit, OnChanges {

  @Input() weekConfig: WeekConfig;
  @Output() dayFocus: EventEmitter<Day> = new EventEmitter();
  @Output() weekState: EventEmitter<DayWithConfig[]> = new EventEmitter();
  @Output() dayChange: EventEmitter<{ isActivated: boolean, day: Day }> = new EventEmitter();
  selectedTimeOption: any;
  selectedOption: any;

  selectedWeek: moment.Moment;
  configuredWeekdays: DayWithConfig[] = [];
  
  readonly days: DayWithConfig[] = [

    {
      key: 'mon',
      label: 'Monday',
      index: 1,
      shortLabel: 'M',
    },
    {
      key: 'tue',
      label: 'Tuesday',
      index: 2,
      shortLabel: 'Tu'
    },
    {
      key: 'wed',
      label: 'Wednesday',
      index: 3,
      shortLabel: 'W'
    },
    {
      key: 'thu',
      label: 'Thursday',
      index: 4,
      shortLabel: 'Th'
    },
    {
      key: 'fri',
      label: 'Friday',
      index: 5,
      shortLabel: 'F'
    },
    {
      key: 'sat',
      label: 'Saturday',
      index: 6,
      shortLabel: 'S'
    },
    {
      key: 'sun',
      label: 'Sunday',
      index: 0,
      shortLabel: 'S'
    },
  ]
  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.refreshConfig();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.weekConfig) {
      this.weekConfig = changes.weekConfig?.currentValue;
    }
    this.refreshConfig();
    this.cdr.detectChanges();
  }

  refreshConfig() {
    if (!this.weekConfig) {
      throw new Error('No week configuration!');
    }
    this.selectedWeek = moment(this.weekConfig?.weekStartDate).startOf('week');
    const configuredDays = this.days.map(d => {
      // const config = this.weekConfig.weekDayConfig?.find(c => {
      //   if(c.day) {
      //     return c.day === d.index;
      //   }
      //   if(c.date) {
      //     const dayByDate = this.selectedWeek.date(c.date?.getMilliseconds());
      //     return d.index === dayByDate.weekday()
      //   }
      //   return false;
      // });
      // if(config) {
      //   d = {...config, ...d};
      // }

      d['date'] = new Date(this.selectedWeek.add(d.index, 'days').toDate());
      this.selectedWeek.subtract(d.index, 'days')
      return d;
    });
    this.configuredWeekdays = configuredDays;
  }

  updateState(day: DayWithConfig) {
    const modifiedDay = this.configuredWeekdays.find(d => {
      return d.index === day.index
    });
    modifiedDay['isActivated'] = day.isActivated;
    this.weekState.emit(this.configuredWeekdays);
  }

  onDayFocus(day: DayWithConfig) {
    if (day.isDisabled) {
      return;
    }
    // this.updateState(day);
    this.dayFocus.emit(day);
  }

  onDayChange(evt, day: DayWithConfig) {
    if (day.isDisabled) {
      return;
    }
    this.updateState({ ...day, isActivated: evt.target.checked });
    this.dayChange.emit({ day, isActivated: evt.target.checked });
  }
  onSelectionTimeChanged(event: any) {
    console.log('Selected time option:', event);
  }
  onSelectionChanged(event: any) {
    console.log('Selected option:', event);
  }
}
