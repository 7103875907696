export const environment = {
  production: true,
  domainSlug: false,
  deeplink_url: 'hotelgenie',
  app_web_url: 'https://hotelritz.pipeline.mobilegenie.app',
  // API_ENDPOINT: 'https://megatron.apigenie.app/api/v1/',
  // WS_ENDPOINT: 'https://megatron.apigenie.app',
  API_ENDPOINT: 'https://staging.apigenie.app/api/v1/',
  WS_ENDPOINT: 'https://staging.apigenie.app',
  VIDEO_AI_ENDPOINT: 'https://videoai.phonegenie.app',
  firebaseConfig: {
    apiKey: "AIzaSyCphIAmg65eq2dHCEcuXfR5NfkjK0wnWoI",
    authDomain: "hg-fcm.firebaseapp.com",
    projectId: "hg-fcm",
    storageBucket: "hg-fcm.appspot.com",
    messagingSenderId: "613761394",
    appId: "1:613761394:web:6cc3748a22c1e09d8a9deb",
    measurementId: "G-ZLPYVDWT90"
  }
};
